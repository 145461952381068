import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import Axios from 'axios';
import { Grid, Container, Button, Typography } from '@material-ui/core';
import logo from '../assets/images/logo.png';
import background_web from '../assets/images/background_web.png';
import PasswordField from '../components/PasswordField';
import { password } from '../utils/constants';
import { tokenService } from '../services/token';
import { SuccessScreenProps } from './SuccessScreen';

interface ResetPasswordFormValues {
  email: string;
  token: string;
  domain: string;
  password: string;
  passwordConfirm: string;
}
interface ResetPasswordFormErrors {
  password?: string;
  passwordConfirm?: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface PasswordResetFormProps {
  email: string;
  domain: string;
  token: string;
  onSuccess: () => void;
  onError: () => void;
  isSubmitDisabled: boolean;
  disableSubmitButtonHandler: (disableButton: boolean) => void;
}

function PasswordResetForm(props: PasswordResetFormProps) {
  const {
    email,
    domain,
    token,
    onSuccess,
    onError,
    isSubmitDisabled,
    disableSubmitButtonHandler,
  } = props;
  return (
    <Formik
      initialValues={{
        email,
        domain,
        token,
        password: '',
        passwordConfirm: '',
      }}
      validate={(values: ResetPasswordFormValues) => {
        const errors: ResetPasswordFormErrors = {};
        if (!values.password) {
          errors.password = password.ERROR_MSG_NO_VALUE;
        } else if (values.password.length < password.LENGTH) {
          errors.password = password.ERROR_MSG_INVALID;
        }
        if (values.password !== values.passwordConfirm) {
          errors.passwordConfirm = password.ERROR_MSG_MISSMATCH;
        }
        const disableButton =
          (errors.password || errors.passwordConfirm) !== undefined;
        disableSubmitButtonHandler(disableButton);
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const body = {
            email: values.email,
            domain: values.domain,
            token: values.token,
            password: values.password,
          };
          await Axios.post('/password-resets/accept', body, {
            baseURL: process.env.REACT_APP_API_URL,
          });
          onSuccess();
        } catch (error) {
          onError();
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container direction="column" alignContent="center" spacing={3}>
            <Grid container item xs={12} md={6} lg={6}>
              <Field
                name="password"
                required
                component={PasswordField}
                label="Contraseña"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid container item xs={12} md={6} lg={6}>
              <Field
                name="passwordConfirm"
                required
                component={PasswordField}
                label="Repite tu contraseña"
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitDisabled || isSubmitting}
              style={{ marginTop: 97, fontWeight: 'bold' }}
              size="large"
            >
              Recuperar
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

const ResetPasswordScreen: React.FC = props => {
  const query = useQuery();
  const domain = query.get('domain') || '';
  const email = query.get('email') || '';
  const token = query.get('token') || '';
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function checkToken() {
      const { isValid } = await tokenService.validateToken(
        '/password-resets/check',
        domain,
        email,
        token,
      );
      if (!isValid) {
        history.push('/broken-link');
      }
    }

    checkToken();
  }, [domain, email, token, history]);

  const disableSubmitButtonHandler = (disableButton: boolean): void => {
    if (disableButton) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  };

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -1,
          marginBottom: 15,
        }}
      >
        <img
          src={background_web}
          style={{ width: '100%', height: '100%' }}
          alt="Background"
        />
      </div>
      <Container maxWidth="md" style={{ textAlign: 'center' }}>
        <img
          src={logo}
          style={{ marginTop: 87, marginBottom: 65, height: 100 }}
          alt="FollowUP"
        />

        {true && (
          <>
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ marginBottom: 23, fontWeight: 100 }}
            >
              Recuperar Contraseña
            </Typography>
            <Container>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                style={{ fontSize: 14 }}
              >
                Empresa
                <Container>
                  <img
                    src={logo}
                    style={{
                      marginTop: 7,
                      marginBottom: 30,
                      height: 40,
                      width: 114.5,
                    }}
                    alt="Logo"
                  />
                </Container>
              </Typography>
            </Container>
            <PasswordResetForm
              token={token}
              email={email}
              domain={domain}
              onSuccess={() => {
                const successPageProps: SuccessScreenProps = {
                  title: '¡Genial!',
                  message: 'El cambio de contraseña ha sido exitoso.',
                  subMessage:
                    'Vuelve a la app e inicia sesión con tu nueva contraseña.',
                };
                history.push('/success', successPageProps);
              }}
              onError={() =>
                alert(
                  'Lo sentimos, ha ocurrido un error. Por favor, intentelo nuevamente.',
                )
              }
              isSubmitDisabled={isSubmitDisabled}
              disableSubmitButtonHandler={disableSubmitButtonHandler}
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default ResetPasswordScreen;
