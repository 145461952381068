import { IconButton, InputAdornment } from '@material-ui/core';
import { TextField, TextFieldProps } from 'formik-material-ui';
import React, { useState } from 'react';
import SvgEye from './svg/Eye';
import SvgEyeClosed from './svg/EyeClosed';

function PasswordField(props: TextFieldProps) {
  const [secureText, setSecureText] = useState(true);
  return (
    <TextField
      type={secureText ? 'password' : 'text'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setSecureText(!secureText);
              }}
              onMouseDown={event => event.preventDefault()}
            >
              {secureText ? (
                <SvgEye fontSize="small" htmlColor="white" />
              ) : (
                <SvgEyeClosed fontSize="small" htmlColor="white" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export default PasswordField;
