import React from 'react';
import styles from '../styles/BackgroundContainer.module.css';
import background_web from '../assets/images/background_web.png';

const BackgroundContainer: React.FC = props => {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -1,
          marginBottom: 15,
        }}
      >
        <img
          src={background_web}
          className={styles.backgroundImage}
          alt="Background"
        />
      </div>
      {props.children}
    </div>
  );
};

export default BackgroundContainer;
