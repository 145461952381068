import Axios from 'axios';

export const tokenService = {
  validateToken: async (
    url: string,
    domain: string,
    email: string,
    token: string,
  ): Promise<{ isValid: boolean }> => {
    try {
      const params = { email, domain, token };
      await Axios.get(url, {
        params,
        baseURL: process.env.REACT_APP_API_URL,
      });
      return { isValid: true };
    } catch (error) {
      return { isValid: false };
    }
  },
};
