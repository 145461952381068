import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const SvgVerified = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42.522 42.523" {...props}>
    <path d="M35.942 6.581A20.761 20.761 0 006.581 35.942 20.761 20.761 0 1035.942 6.581zM21.261 39.59A18.328 18.328 0 1139.59 21.261 18.349 18.349 0 0121.261 39.59z" />
    <path d="M31.179 14.6a1.217 1.217 0 00-1.72 0L18.717 25.342l-5.655-5.655a1.216 1.216 0 00-1.72 1.72l6.515 6.515a1.216 1.216 0 001.72 0l11.6-11.6a1.216 1.216 0 00.002-1.722z" />
  </SvgIcon>
);

export default SvgVerified;
