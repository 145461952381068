import React from 'react';
import { Container, Typography } from '@material-ui/core';
import logo from '../assets/images/logo.png';
import BackgroundContainer from '../components/BackgroundContainer';
import SvgAngerFace from '../components/svg/AngerFace';

const BrokenLinkScreen: React.FC = () => {
  return (
    <BackgroundContainer>
      <Container maxWidth="md" style={{ textAlign: 'center' }}>
        <img
          src={logo}
          style={{ marginTop: 87, marginBottom: 120, height: 100 }}
          alt="FollowUP"
        />
        <>
          <Container
            style={{ flexDirection: 'column', justifyContent: 'center' }}
          >
            <Container style={{ marginBottom: '34px' }}>
              <SvgAngerFace
                style={{ width: '60px', height: '60px' }}
              ></SvgAngerFace>
            </Container>
            <Container style={{ marginBottom: '14px' }}>
              <Typography
                variant="h1"
                color="textPrimary"
                style={{ fontSize: 20, fontWeight: 'bold' }}
              >
                ¡Ups, hay problemas con este enlace!
              </Typography>
            </Container>
            <Container style={{ marginBottom: '176px' }}>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ fontSize: 15, fontWeight: 100 }}
              >
                Es posible que este enlace haya caducado o ya lo hayas
                utilizado.
              </Typography>
            </Container>
          </Container>
        </>
      </Container>
    </BackgroundContainer>
  );
};

export default BrokenLinkScreen;
