import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import AcceptInvitationScreen from './screens/AcceptInvitationScreen';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ResetPasswordScreen from './screens/ResetPasswordScreen';
import BrokenLinkScreen from './screens/BrokenLinkScreen';
import SuccessScreen from './screens/SuccessScreen';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 718,
      lg: 900,
      xl: 1200,
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
      disableRipple: true,
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiDialogContent: {
      root: {
        paddingTop: 36,
        paddingBottom: 19,
      },
    },

    MuiButton: {
      label: {
        fontFamily: [
          'CaviarDreams',
          'Roboto',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        fontWeight: 'bold',
      },
      containedSizeLarge: {
        paddingTop: 14,
        paddingBottom: 14,
      },
      contained: {
        '&$disabled': {
          color: 'white',
          'background-color': '#143651',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily:
          [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(',') + '!important',
        fontWeight: 'bold',
      },
    },
    MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottom: '1px solid rgba(255, 255, 255, 0.30)',
        },
      },
    },

    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1rem',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.1rem',
        '&$focused': {
          color: '#FFFFFF',
        },
        '&$error': {
          color: '#FFFFFF',
        },
      },
    },
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#143651',
      dark: '#3D4475',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#239ae9',
      dark: '#239ae9',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#163451',
    },
    // error: will use the default color
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/recuperar-contrasena">
            <ResetPasswordScreen />
          </Route>
          <Route
            path="/success"
            render={routeProps => (
              <SuccessScreen {...routeProps.location.state} />
            )}
          />
          <Route path="/broken-link">
            <BrokenLinkScreen />
          </Route>
          <Route path="/">
            <AcceptInvitationScreen />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
