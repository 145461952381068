import React from 'react';
import { SvgIconProps, SvgIcon } from '@material-ui/core';

const SvgLock = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 8.033 10.452" {...props}>
    <path d="M7.464 4.221h-.2v-.953A3.249 3.249 0 004.112 0h-.191A3.249 3.249 0 00.773 3.267v.953h-.2a.661.661 0 00-.57.724v4.782a.664.664 0 00.57.726h6.894a.664.664 0 00.57-.726V4.945a.661.661 0 00-.573-.724zm-2.8 3.109v1.445a.308.308 0 01-.3.305H3.68a.308.308 0 01-.3-.305V7.33a.875.875 0 01.55-1.488h.191a.875.875 0 01.55 1.488zm1.255-3.109h-3.8v-.953a1.9 1.9 0 113.8 0v.953z" />
  </SvgIcon>
);

export default SvgLock;
