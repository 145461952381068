import React from 'react';
import { Container, Typography } from '@material-ui/core';
import logo from '../assets/images/logo.png';
import BackgroundContainer from '../components/BackgroundContainer';
import SvgVerified from '../components/svg/Verified';

export interface SuccessScreenProps {
  title?: string;
  message?: string;
  subMessage?: string;
}

type SuccessScreenComponent = React.FC<SuccessScreenProps>;

const SuccessScreen: SuccessScreenComponent = props => {
  const { title, message, subMessage } = props;

  return (
    <BackgroundContainer>
      <Container maxWidth="md" style={{ textAlign: 'center' }}>
        <img
          src={logo}
          style={{ marginTop: 87, marginBottom: 120, height: 100 }}
          alt="FollowUP"
        />

        <>
          <Container
            style={{ flexDirection: 'column', justifyContent: 'center' }}
          >
            <Container style={{ marginBottom: '34px' }}>
              <SvgVerified
                style={{ width: '60px', height: '60px', fill: '#fff' }}
              ></SvgVerified>
            </Container>
            <Container style={{ marginBottom: '14px' }}>
              <Typography
                variant="h1"
                color="textPrimary"
                style={{ fontSize: 20, fontWeight: 'bold' }}
              >
                {title}
              </Typography>
            </Container>
            <Container style={{ marginBottom: '176px' }}>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ fontSize: 15, fontWeight: 100 }}
              >
                {message}
              </Typography>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ fontSize: 15, fontWeight: 100 }}
              >
                {subMessage}
              </Typography>
            </Container>
          </Container>
        </>
      </Container>
    </BackgroundContainer>
  );
};

export default SuccessScreen;
