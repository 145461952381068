import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const SvgEyeClosed = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 17 3" {...props}>
    <path d="M16.48 1.26l.125-.1a.718.718 0 00.132-.888.482.482 0 00-.742-.159C7.656 7.085 1.586.456 1.33.171a.476.476 0 00-.755.027.73.73 0 00.021.9 7.563 7.563 0 00.548.528L.076 3.733a.715.715 0 00.179.877.478.478 0 00.278.093.523.523 0 00.456-.306l1.045-2.056a12.056 12.056 0 002.231 1.285L3.75 5.684a.662.662 0 00.356.793l.154.027a.556.556 0 00.51-.455l.51-2.03a10.278 10.278 0 002.747.5v2.039a.542.542 0 101.066 0v-2.05a10.7 10.7 0 002.755-.564l.656 2.069a.546.546 0 00.5.411l.189-.04a.681.681 0 00.31-.822l-.639-2.016a14.9 14.9 0 002.718-1.582l.864 1.379a.512.512 0 00.426.256.469.469 0 00.319-.128.723.723 0 00.108-.893z" />
  </SvgIcon>
);

export default SvgEyeClosed;
