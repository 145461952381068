import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const SvgEye = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 17 9.917" {...props}>
    <path d="M16.937 4.756C16.803 4.562 13.586 0 8.5 0 4.136 0 .247 4.536.084 4.729a.354.354 0 000 .458C.248 5.38 4.136 9.916 8.5 9.916s8.253-4.536 8.416-4.729a.355.355 0 00.021-.431zM8.5 7.792a2.833 2.833 0 112.833-2.833A2.836 2.836 0 018.5 7.792z" />
  </SvgIcon>
);

export default SvgEye;
