import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const SvgAngerFace = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 60.824 60.824" {...props}>
    <path
      fill="#fff"
      d="M30.412,0A30.412,30.412,0,1,0,60.824,30.412,30.412,30.412,0,0,0,30.412,0Zm0,57.022a26.61,26.61,0,1,1,26.61-26.61A26.61,26.61,0,0,1,30.412,57.022Z"
    />
    <path
      fill="#fff"
      d="M139.252,142.315a1.9,1.9,0,0,0-.418-2.072l-7.6-7.6-2.68,2.7,4.372,4.353H128v3.8h9.5A1.9,1.9,0,0,0,139.252,142.315Z"
      transform="translate(-112.794 -116.883)"
    />
    <path
      fill="#fff"
      d="M298.771,135.339l-2.7-2.7-7.6,7.6a1.9,1.9,0,0,0,1.35,3.25h9.5v-3.8H294.4Z"
      transform="translate(-253.705 -116.883)"
    />
    <path
      fill="#fff"
      d="M143.206,256A15.206,15.206,0,0,0,128,271.206h3.8a11.4,11.4,0,1,1,22.809,0h3.8A15.206,15.206,0,0,0,143.206,256Z"
      transform="translate(-112.794 -225.588)"
    />
  </SvgIcon>
);

export default SvgAngerFace;
