import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Formik, Form, Field } from 'formik';
import Axios from 'axios';
import { TextField } from 'formik-material-ui';
import {
  InputAdornment,
  Grid,
  Container,
  Button,
  Typography,
} from '@material-ui/core';
import logo from '../assets/images/logo.png';
import background_web from '../assets/images/background_web.png';
import SvgLock from '../components/svg/Lock';
import PasswordField from '../components/PasswordField';
import { password } from '../utils/constants';
import { tokenService } from '../services/token';
import { useHistory } from 'react-router-dom';
import { SuccessScreenProps } from './SuccessScreen';

interface AcceptInvitationFormValues {
  email: string;
  token: string;
  domain: string;
  password: string;
  passwordConfirm: string;
}
interface AcceptInvitationFormErrors {
  password?: string;
  passwordConfirm?: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AcceptInvitationScreen: React.FC = () => {
  const query = useQuery();
  const domain = query.get('domain') || '';
  const email = query.get('email') || '';
  const token = query.get('token') || '';
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function checkToken() {
      const { isValid } = await tokenService.validateToken(
        '/invitations/check',
        domain,
        email,
        token,
      );
      if (!isValid) {
        history.push('/broken-link');
      }
    }

    checkToken();
  }, [domain, email, token, history]);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: -1,
          marginBottom: 15,
        }}
      >
        <img
          src={background_web}
          style={{ width: '100%', height: '100%' }}
          alt="Background"
        />
      </div>
      <Container maxWidth="md" style={{ textAlign: 'center' }}>
        <img
          src={logo}
          style={{ marginTop: 87, marginBottom: 65, height: 100 }}
          alt="Logo"
        />
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ marginBottom: 93, fontWeight: 100 }}
        >
          Has sido invitado a usar FollowUP, por favor establece una contraseña.
        </Typography>
        <Formik
          initialValues={{
            email,
            domain,
            token,
            password: '',
            passwordConfirm: '',
          }}
          validate={(values: AcceptInvitationFormValues) => {
            const errors: AcceptInvitationFormErrors = {};
            if (!values.password) {
              errors.password = password.ERROR_MSG_NO_VALUE;
            } else if (values.password.length < password.LENGTH) {
              errors.password = password.ERROR_MSG_INVALID;
            }
            if (values.password !== values.passwordConfirm) {
              errors.passwordConfirm = password.ERROR_MSG_MISSMATCH;
            }
            const disableButton =
              (errors.password || errors.passwordConfirm) !== undefined;
            setIsSubmitDisabled(disableButton);
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await Axios.post('/invitations/accept', values, {
                baseURL: process.env.REACT_APP_API_URL,
              });
              const successPageProps: SuccessScreenProps = {
                title: '¡Genial!',
                message: '¡Contraseña establecida con éxito!',
                subMessage:
                  'Ya puedes ingresar a través de la app de FollowUP.',
              };
              history.push('/success', successPageProps);
            } catch (error) {
              alert('Algo malo pasó :( ' + error);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field
                    name="domain"
                    component={TextField}
                    label="Empresa"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgLock fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" disableTypography={true}>
                          .fupbi.com
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                    component={TextField}
                    label="Email"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgLock fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="password"
                    required
                    component={PasswordField}
                    label="Contraseña"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="passwordConfirm"
                    required
                    component={PasswordField}
                    label="Repite tu contraseña"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitDisabled || isSubmitting}
                fullWidth
                style={{ marginTop: 97, fontWeight: 'bold' }}
                size="large"
              >
                Listo
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
};

export default AcceptInvitationScreen;
